































import { Component, Vue } from 'vue-property-decorator'
import LabelBase from '@/components/atoms/LabelBase.vue'
import TableBase from '@/components/atoms/TableBase1110.vue'
import ButtonBase from '@/components/atoms/ButtonBase.vue'
import RadioToggle from '@/components/atoms/RadioToggle.vue'
import { ServiceCodeEnum } from '@/types/teacher/service'

@Component({
  components: {
    LabelBase,
    TableBase,
    ButtonBase,
    RadioToggle,
  },
})
export default class RequestDefault extends Vue {
  private academyId = Vue.prototype.$cookies.get('dataGdls').academyId

  private showOverlay = false

  private defaultTableBasePerPage?: number

  private defaultTableBaseItems: {
    serviceName: string
    serviceCode: string
    ko: { checked: boolean; on: string; off: string; id: number; onChange: Function }
    su: { checked: boolean; on: string; off: string; id: number; onChange: Function }
    ei: { checked: boolean; on: string; off: string; id: number; onChange: Function }
    ri: { checked: boolean; on: string; off: string; id: number; onChange: Function }
    sh: { checked: boolean; on: string; off: string; id: number; onChange: Function }
  }[] = []

  private defaultTableBaseFields = [
    { key: 'serviceName', label: 'サービス名' },
    { key: 'su', label: '算数/数学' },
    { key: 'ei', label: '英語' },
    { key: 'ko', label: '国語' },
    { key: 'ri', label: '理科' },
    { key: 'sh', label: '社会' },
  ]
  private onOffCalendar = {
    isEnabled: false,
    id: '',
  }
  private onOffGpt = {
    isEnabled: false,
    id: '',
  }

  private defaultTableBaseToggles = ['ko', 'su', 'ei', 'ri', 'sh']

  private mounted() {
    this.loadDatas()
  }

  private changeConnectedToggle(params: { serviceCode: string; subjectCode: string }): boolean {
    const serviceCode = params.serviceCode
    const subjectCode = params.subjectCode
    // GDLS変更
    if (serviceCode === 'gdls') {
      const gdlsItems = this.defaultTableBaseItems.find((item) => item.serviceCode === 'gdls')
      if (gdlsItems && gdlsItems[subjectCode].checked) {
        // GDLSがOFFになる場合
        const gakkenItems = this.defaultTableBaseItems.find((item) => item.serviceCode === 'gakken')
        if (gakkenItems && gakkenItems[subjectCode].checked) {
          // かつ対応する学研動画がONの場合
          if (!confirm('GDLSを削除すると学研動画も削除対象となります。')) {
            // 処理終了
            return false
          }
          // 対応する学研動画をOFFに
          gakkenItems[subjectCode].checked = false
        }
      }
    }
    // 学研動画変更
    else if (serviceCode === 'gakken') {
      const gakkenItems = this.defaultTableBaseItems.find((item) => item.serviceCode === 'gakken')
      if (gakkenItems && !gakkenItems[subjectCode].checked) {
        // 学研動画がONになる場合
        const gdlsItems = this.defaultTableBaseItems.find((item) => item.serviceCode === 'gdls')
        if (gdlsItems && !gdlsItems[subjectCode].checked) {
          // 対応するGDLSがOFFの場合アラートを出してOFFに戻す
          alert('学研動画のみのご利用はできません。GDLSをご利用の場合のみ利用可能です。')
          return false
        }
      }
    }
    return true
  }

  private radioToggleConfirm(serviceCode: string, data: any): { [key: string]: any } {
    return {
      onClick: this.changeConnectedToggle,
      onClickParams: {
        serviceCode: serviceCode,
        subjectCode: data.subjectCode,
      },
    }
  }

  private async loadDatas() {
    // サービスデフォルト情報を取得
    Vue.prototype.$http.httpWithToken
      .get(`/services/default`, {
        params: {
          academyId: this.academyId,
        },
      })
      .then((res: any) => {
        this.showOverlay = !res.data.isUpdatable
        if (!res.data.services.length) return
        const subjectsCodes = res.data.services[0].subjects.map(
          ({ subjectCode }: { subjectCode: string }) => subjectCode
        )
        this.defaultTableBaseItems = res.data.services
          .filter((service: any) => {
            if (ServiceCodeEnum.GPT_SERVICE_CODE === service.serviceCode) {
              this.onOffGpt = {
                id: service.subjects[0].serviceDefaultId,
                isEnabled: service.subjects[0].isEnabled,
              }
            }
            if (ServiceCodeEnum.CALENDAR_SERVICE_CODE === service.serviceCode) {
              this.onOffCalendar = {
                id: service.subjects[0].serviceDefaultId,
                isEnabled: service.subjects[0].isEnabled,
              }
            }
            return ![ServiceCodeEnum.GPT_SERVICE_CODE, ServiceCodeEnum.CALENDAR_SERVICE_CODE].includes(service.serviceCode)
          })
          .map(
            (service: {
              serviceId: number
              serviceName: string
              serviceCode: string
              subjectCode: string
              subjects: any
            }) => {
              const subjects = [...Array(5)].map((_: any, subjectIndex: number) => {
                return {
                  checked: service.subjects[subjectIndex].isEnabled,
                  on: '',
                  off: '',
                  id: service.subjects[subjectIndex].serviceDefaultId,
                  radioToggleConfirm: this.radioToggleConfirm(service.serviceCode, service.subjects[subjectIndex]),
                }
              })
              return {
                serviceName: service.serviceName,
                serviceCode: service.serviceCode,
                [subjectsCodes[0]]: subjects[0],
                [subjectsCodes[1]]: subjects[1],
                [subjectsCodes[2]]: subjects[2],
                [subjectsCodes[3]]: subjects[3],
                [subjectsCodes[4]]: subjects[4],
              }
            }
          )
      })
  }

  private submitDefault(): void {
    const params = []
    for (const item of this.defaultTableBaseItems) {
      for (const subjectCode of ['ko', 'su', 'ei', 'ri', 'sh']) {
        const param = {
          id: item[subjectCode].id,
          isEnabled: item[subjectCode].checked,
        }
        params.push(param)
      }
    }
    params.push(this.onOffCalendar, this.onOffGpt)
    Vue.prototype.$http.httpWithToken
      .patch(`/services/default`, {
        academyId: this.academyId,
        serviceDefault: params,
      })
      .then(() => {
        alert('更新しました。')
      })
      .catch((error: any) => {
        if (error.response.data.status === 409) {
          alert('一括申請中のため更新できません。')
          location.reload()
        }
      })
  }

  private submitAndApplyDefault(): void {
    const params = []
    for (const item of this.defaultTableBaseItems) {
      for (const subjectCode of ['ko', 'su', 'ei', 'ri', 'sh']) {
        const param = {
          id: item[subjectCode].id,
          isEnabled: item[subjectCode].checked,
        }
        params.push(param)
      }
    }
    params.push(this.onOffCalendar, this.onOffGpt)
    Vue.prototype.$http.httpWithToken
      .post(`/requests`, {
        academyId: this.academyId,
        requestType: 3,
        requests: params,
      })
      .then(() => {
        alert('全教室へのデフォルト反映を申請しました。')
        location.reload()
      })
      .catch((error: any) => {
        if (error.response.data.status === 409) {
          alert('既に申請中のため更新に失敗しました。')
          location.reload()
        }
      })
  }

  get calcOverlaySize(): { [key: string]: string } {
    return {
      '--wrapper-visible': this.showOverlay ? 'block' : 'none',
    }
  }
}
